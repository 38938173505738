<template>
  <div class="tips" v-if="tipsVisible">
    <div class="tips-background"></div>
    <div class="tips-main">
      <div class="tips-main-content">
        <div class="tips-main-content-title">公告</div>
        <div class="tips-main-content-content" >
          <dd>关于不法分子冒充“易借速贷”官方APP实施诈骗的提示</dd>
          <dd>近日，浙江未讯科技有限公司旗下信贷平台“易借速贷”（以下简称本平台）发现存在不法分子假冒仿冒我司官方易借速贷APP上架至App Store应用商店。请大家注意识别，认准我司官方易借速贷APP，谨防上当受骗。同时下载时仔细核对LOGO等是否为我司易借速贷官方产品，及时通过官网、微信等渠道联系本平台易借速贷官方进行核实。</dd>
          <dd>同时，在此郑重警告：本平台将维权到底，决不允许任何个人和机构侵犯本平台、用户及合作伙伴的权益。</dd>
          <dd>特此声明!</dd>
          <dt>浙江未讯科技有限公司</dt>
          <dt>2024年6月6日</dt>
        </div>
      </div>
      <img src="@/assets/img/mobile/home/home_btn_close.png" class="home-dialog-close"  @click="handleDilogClose">
    </div>
  </div>
</template>

<script>
export default {
  watch:{
    tipsVisible: {
      handler(newVal) {
        console.log(newVal);
        if(newVal) {
          this.handleStopMove()
        } else {
          this.handleMove()
        }
      }
    }
  },
  mounted() {
    this.handleStopMove()
  },
  data() {
    return {
      tipsVisible: true
    }
  },
  methods: {
    handleDilogClose() {
      this.tipsVisible = false
    },
    //停止页面滚动
    handleStopMove(){
      let m = function(e){e.preventDefault();};
      document.
      document.addEventListener("touchmove",m,{ passive:false });
    },
    //开启页面滚动
    handleMove(){
      let m =function(e){e.preventDefault();};
      document.body.style.overflow='';//出现滚动条
      document.removeEventListener("touchmove",m,{ passive:true });
    }
  }
}
</script>

<style lang="less">
.tips {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;

  &-background {
    width: 100%;
    height: 10000px;
    top: 100%;
    position: absolute;
    background: rgba(00, 00, 00, 0.3);
  }

  &-main {
    position: fixed;
    top: 15%;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-content {
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #fff;
      width: 310px;
      height: 340px;

      &-title {
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Semibold;
        font-weight: 600;
        text-align: center;
        color: #1e1f20;
        padding-top: 14px;
        padding-bottom: 14px;
        width: 100%;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        background: #f8f8f8;
      }

      &-content {
        display: flex;
        overflow: scroll;
        flex-direction: column;
        background: #fff;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        height: 100%;
        padding-left: 24px;
        padding-right: 24px;
        margin-bottom: 14px;
          
        dd {
          margin-top: 16px;
          font-size: 14px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: left;
          color: #4f4f4f;
          line-height: 20px;
        }
        dt {
          font-size: 14px;
          font-family: PingFangSC, PingFangSC-Regular;
          width: 100%;
          font-weight: 400;
          text-align: right;
          color: #4f4f4f;
          line-height: 20px;
        }
      }

    }
  }

  &-close {
    margin-top: 32px;
    width: 32px;
    height: 32px;
  }
}
</style>