<template>
  <div class="home" id="home">
    <div class="home-content">
      <Header></Header>
      <div class="home-main">
        <img class="home-banner" src="@/assets/img/mobile/home/banner.png" alt>
        <div class="home-notice" @click="handleNoticeClick">
          <img class="home-notice-icon" src="@/assets/img/mobile/home/home_notice.png" alt="">
          <div class="home-notice-line"></div>
          <div class="home-notice-txt">关于不法分子冒充“易借速贷”工作人员实施诈骗的声明</div>
        </div>
        <div class="home-title" style="margin-top:32px">
          <img src="@/assets/img/mobile/home/icon_title_left.png" alt>
          <div class="home-title-txt">未讯科技旗下金融科技服务平台</div>
          <img src="@/assets/img/mobile/home/icon_title_right.png" alt>
        </div>
        <img class="home-product-des" style="margin-top:30px" src="@/assets/img/mobile/home/product_des_1.png" alt="">
        <img class="home-product-des" style="margin-top:15px" src="@/assets/img/mobile/home/product_des_2.png" alt="">
        <img class="home-product-des" style="margin-top:15px" src="@/assets/img/mobile/home/product_des_3.png" alt="">
        <div class="home-title" style="margin-top:33px">
          <img src="@/assets/img/mobile/home/icon_title_left.png" alt>
          <div class="home-title-txt">轻松申请，3步拿钱</div>
          <img src="@/assets/img/mobile/home/icon_title_right.png" alt>
        </div>
        <img class="home-product-step" style="margin-top:17px" src="@/assets/img/mobile/home/step.png" alt="">
        <div class="home-company">
          <div class="home-title">
            <img src="@/assets/img/mobile/home/title_white_left.png" alt>
            <div class="home-title-white-txt">关于易借</div>
            <img src="@/assets/img/mobile/home/title_white_right.png" alt>
          </div>
          <dd style="margin-top:7px">专于科技，为您而来</dd>
          <dt style="margin-top:19px">易借速贷是浙江未讯科技有限公司旗下信贷服务品牌，基于人工智能和大数据风控应用能力，与银行等金融机构伙伴合作，提供面向大众的个人信贷服务，具有申请简便、审批快、额度高、息费低、到账快等特点。易借速贷能够根据用户的需求进行精准匹配，致力于成为受用户欢迎，受伙伴信任的金融科技平台。</dt>
          <ul class="home-img-box">
            <li v-for="(item, index) in companyDesList" :key="index">
              <img class="home-img-box-style1" :src="item.imgUrl" alt>
            </li>
          </ul>
        </div>
        <div class="home-title" style="margin-top:32px">
          <img src="@/assets/img/mobile/home/icon_title_left.png" alt>
          <div class="home-title-txt">部分合作伙伴</div>
          <img src="@/assets/img/mobile/home/icon_title_right.png" alt>
        </div>
        <ul class="home-img-box">
          <li v-for="(item, index) in cooperationList" :key="index">
            <img class="home-img-box-style2" :src="item.imgUrl" alt>
          </li>
        </ul>
        <div class="home-title" style="margin-top:32px">
          <img src="@/assets/img/mobile/home/icon_title_left.png" alt>
          <div class="home-title-txt">易借速贷防诈骗指南</div>
          <img src="@/assets/img/mobile/home/icon_title_right.png" alt>
        </div>
        <div class="home-tips" style="margin-top:16px">1、请通过手机官方应用市场或官方网站下载“易借速贷”APP，在任何情况下都不要泄露您的个人账号与手机验证码信息。请注意保护资金及个人隐私安全，确保本人操作。</div>
        <div class="home-tips">2、易借速贷的授信额度来自正规金融机构，仅与个人信用资质有关，不受其他第三方因素影响，请勿与任何声称“帮忙借款”的 “内部员工”“第三方代理”等进行联系和交易。</div>
        <div class="home-tips">3、除易借速贷APP上展示的还款计划外，我们不会以任何理由收取其他附加费用，如账户管理费、保证金、手续费、评估费、中介费、账户解冻费、人工审核费等各种费用。如遇相关情况，请拨打官方客服电话400-777-8802 (9:00-21:00)进行核实。</div>
        <div class="home-tips">4、易借速贷的借还款信息均以官方APP显示为准，切勿相信“代还款”“提前结清贷款”等名义的转账、汇款。</div>
        <div class="home-tips" style="margin-bottom:42px">5、任何冒用及假借我司名义或合作机构进行违法活动的网站、单位或个人，一经发现，我司将依法追究其法律责任。</div>
      </div>
      <Footer></Footer>
    </div>
    <div class="home-download-bg"></div>
    <div class="home-download-btn" @click="handleDownLoad()"></div>
    <TipsDiaglog ref="tipsDialog"></TipsDiaglog>
    <img @click="handlecontact" class="home-customer-service" src="@/assets/img/mobile/home/customer-service.png" alt="">
  </div>
</template>

<script>
import Header from '@/components/mobile/header'
import Footer from '@/components/mobile/footer'
import TipsDiaglog  from '@/components/mobile/tips'
import { getOsFrom } from '@/config/common.js'

export default {
  components: { Header, Footer, TipsDiaglog },

  data() {
    return {
      companyDesList: [
        { imgUrl: require('@/assets/img/mobile/home/company_des_1.png') },
        { imgUrl: require('@/assets/img/mobile/home/company_des_2.png') },
        { imgUrl: require('@/assets/img/mobile/home/company_des_3.png') },
        { imgUrl: require('@/assets/img/mobile/home/company_des_4.png') },
        { imgUrl: require('@/assets/img/mobile/home/company_des_5.png') },
        { imgUrl: require('@/assets/img/mobile/home/company_des_6.png') }
      ],
      cooperationList: [
        { imgUrl: require('@/assets/img/pc/partners/partners-icon1.png'), title: '无锡锡商银行' },
        { imgUrl: require('@/assets/img/pc/partners/partners-icon8.png'), title: '新颜科技' },
        { imgUrl: require('@/assets/img/pc/partners/partners-icon4.png'), title: '阿里云' },
        { imgUrl: require('@/assets/img/pc/partners/partners-icon5.png'), title: '百融云创' },
        { imgUrl: require('@/assets/img/pc/partners/partners-icon3.png'), title: '旷视' },
        { imgUrl: require('@/assets/img/pc/partners/partners-icon7.png'), title: '同盾科技' }, 
      ],
    }
  },
  methods: {
    handleDownLoad() {
      console.log(getOsFrom());
      if (getOsFrom() === 'ios') {
        window.location.href = "https://itunes.apple.com/cn/app/id1458395825?mt=8"
      } else {
        window.location.href = "https://ltf.ldxinyong.com/web/apk/lzyj.apk"
     }
    },
    //
    handleNoticeClick() {
      this.$refs.tipsDialog.tipsVisible = true
    },
    handlecontact() {
      ysf('open') 
    }
  }
}
</script>

<style lang="less">
  .home {
    &-customer-service{
      position: fixed;
      top: 65%;
      right: 0;
      width: 80px;
      height: 90px;
    }
    &-notice {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      padding-top: 15px;
      padding-bottom: 15px;
      background: #f8f8f8;

      &-icon {
        width: 24px;
        height: 24px;
        margin-left: 15px;
      }

      &-line {
        width: 1px;
        height: 16px;
        background: #ee6c28;
        margin-left: 4px;
      }

      &-txt {
        font-size: 12px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #4f4f4f;
        margin-left: 12px;
        margin-right: 26px;
      }
    }

    &-dialog {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      margin: auto;

      &-background {
        width: 100%;
        height: 10000px;
        background: rgba(00, 00, 00, 0.3);
      }

      &-main {
        border-radius: 12px;
        width: 314px;
        height: 340px;
        display: flex;
        flex-direction: column;
        align-items: center;
        

        &-title {
          font-size: 16px;
          font-family: PingFangSC, PingFangSC-Semibold;
          font-weight: 600;
          text-align: center;
          color: #1e1f20;
          padding-top: 14px;
          padding-bottom: 14px;
          background: #f8f8f8;
        }

        &-content {
          display: flex;
          overflow: scroll;
          flex-direction: column;
          background: #fff;
          height: 100%;
          
          dd {
            font-size: 14px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            text-align: left;
            color: #4f4f4f;
            line-height: 20px;
          }
        }
      }

      &-close {
        margin-top: 32px;
        width: 32px;
        height: 32px;
      }
    }

    &-download {

      &-btn {
        position:fixed;
        width: 320px;
        height: 70px;
        bottom: 19px;
        left: 0px;
        right: 0px;
        margin-left: auto;
        margin-right: auto;
      }

      &-btn:after {
        content: "";
        position: absolute;
        width: 320px;
        height: 70px;
        bottom: 19px;
        left: 0px;
        right: 0px;
        margin-left: auto;
        margin-right: auto;
        background-size: cover;
        background-image: url('../../assets/img/mobile/home/ic_download_btn.png');
        background-position: 50%;
        background-repeat: no-repeat;
      }

      &-bg {
        position:fixed;
        width: 100%;
        height: 137px;
        bottom: 0px;
      }

      &-bg:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 137px;
        bottom: 0px;
        margin-left: auto;
        margin-right: auto;
        background-size: cover;
        background-image: url('../../assets/img/mobile/home/bg_download.png');
        background-position: 50%;
        background-repeat: no-repeat;
      }

    }
    

    &-content {
      display: flex;
      flex-direction: column;
    }
  
    &-main {
      margin-top: 44px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &-banner {
      width: 100%;
      height: 200px;
    }

    &-product-des {
      height: 90px;
      width: 334px;
    }

    &-product-step {
      width: 100%;
      height: auto;
    }

    &-company {
      width: 100%;
      height: auto;
      padding-top: 47px;
      padding-bottom: 35px;
      flex-direction: column;
      align-items: center;
      background-image: url("../../assets/img/mobile/home/company_des.png");

      dd {
        font-size: 13px;
        font-family: PingFang, PingFang-Medium;
        font-weight: 500;
        text-align: center;
        color: #ffffff;
        line-height: 18px; 
      }

      dt {
        font-size: 13px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: justify;
        color: #ffffff;
        line-height: 22px;
        letter-spacing: -1px; 
        padding-left: 38px;
        padding-right: 38px;
      }
    }

    &-img-box {
      margin-top: 15px;
      display: flex;
      flex-flow: row wrap;
      align-content: space-around;

      li {
        margin-top: 18px;
        flex: 0 0 33%;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &-style1 {
        width: 54px;
        height: 80px;
      }

      &-style2 {
        width: 96px;
        height: 37px;
      }
    }

    &-title {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: baseline;

      img {
        width: 46px;
        height: 9px;
      }

      &-white-txt {
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        text-align: center;
        color: #ffffff;
        line-height: 22px;
        margin-left: 11px;
        margin-right: 11px;
      }

      &-txt {
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        text-align: center;
        color: #ee6c28;
        line-height: 22px;
        margin-left: 11px;
        margin-right: 11px;
      }
    }

    &-tips {
      font-size: 13px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: justify;
      color: #4f4f4f;
      line-height: 22px;
      margin-top: 10px;
      margin-left: 38px;
      margin-right: 38px;
    }
  }
</style>